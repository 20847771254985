
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  if (hours === '00') return minutes + ':' + seconds
  else return hours + ':' + minutes + ':' + seconds
}
import Modal from '@/components/Shared/Modal/index.vue'
import Videojs from './videojs.vue'
export default {
  data() {
    return {
      showAlertModal: {
        btnText: '',
        status: false,
        body: '',
        title: '',
        ok: () => false,
      },
      currentTimes: [],
      safari: false,
      ProgressHeight: 0.6,
      video: {},
      percent: 0,
      studyTime: 0,
      controlBar: {
        isWaiting: true,
        status: false,
        duration: 0,
        currentTime: 0,
        loadedProgressBar: 0,
        onPlay: false,
        volume: false,
        onShow: true,
        showTimeout: -1,
        updateDurationInterval: -1,
      },
    }
  },
  components: { Modal, Videojs },
  props: {
    quality: {
      default: 720,
    },
    videoUrl: {
      default: '',
    },
    posterImage: {
      default: '',
    },
    loading: {
      default: true,
    },
    withInfo: {
      default: false,
      type: Boolean,
    },
    single: {
      default: false,
      type: Boolean,
    },
    videoLink: {
      default: () => ({
        token: '',
        link: '',
      }),
    },
    videoStream: {
      default: () => {
        return {
          studyTime: 0,
          nextVideoId: -1,
          prevVideoId: 0,
          isFinished: 1,
          description: '',
          maxWatchedDuration: 0,
          watchedDuration: 0,
          sectionId: 1,
        }
      },
    },
    course: {
      default: () => {
        return {
          title: '',
        }
      },
    },
    product: {
      default: () => {
        return {
          title: '',
        }
      },
    },
    sections: {
      default: () => [],
    },
  },
  beforeDestroy() {
    if (this.controlBar.updateDurationInterval !== -1)
      clearInterval(this.updateDurationInterval)
  },
  methods: {
    SET_REMOVE_INTERVAL() {
      if (this.controlBar.updateDurationInterval !== -1)
        clearInterval(this.controlBar.updateDurationInterval)
    },
    SET_UNDO_REDO_CURRENT_TIME(time) {
      const player = this.$refs.player
      player.currentTime(player.currentTime() + time)
    },
    // quiz' var mı kontrol et
    FIND_QUIZ() {
      const section = this.sections.find(
        (sec) => sec.id === this.course.sectionId
      )
      if (section) {
        const video = section.videos.find(
          (video) => video.id === this.course.videoId
        )
        if (video) {
          const quiz = video.quizzes.length > 0 ? video.quizzes[0] : { id: -1 }
          return quiz
        }
      }
      return { id: -1 }
    },
    // Buradan Aşağısı methodlar kişiselleştirilmek içindir.
    // Video kayıtlı durationu varsa set et.
    SET_SEEKING_VIDEO(video) {
      if (this.single) return
      this.controlBar.loadedProgressBar = 0
      const currentTimeDuration = video.currentTime()
      if (
        this.product.isLocked === 1 &&
        currentTimeDuration > this.course.maxWatchedDuration + 1
      ) {
        this.showAlertModal = {
          status: true,
          btnText: 'OK',
          body: this.$custom$t('courseIsLockedDescription'),
          title: this.$custom$t('courseIsLocked'),
          ok: () => (this.showAlertModal.status = false),
        }
        if (this.currentTimes.length > 0)
          video.currentTime(this.currentTimes[this.currentTimes.length - 1])
        return
      }
      this.$api
        .put('UserCourses', {
          videoId: Number(this.$route.params.videoId),
          isFinished: 0, // video aktif izlendiği için 0 gönderilir.
          watchedDuration: Math.floor(video.currentTime()),
        })
        .then((response) => {
          if (response.message === 'OK') {
            this.$emit('update:time', Math.floor(video.currentTime()))
            this.$store.commit('productDetail/SET_UPDATE_VIDEO', {
              sectionId: this.videoStream.sectionId,
              videoId: Number(this.$route.params.videoId),
              courseId: Number(this.$route.params.productId),
              video: {
                watchedDuration: Math.floor(video.currentTime()),
              },
            })
            this.$emit('update:video')
          }
        })
    },
    SET_INIT_VIDEO_STREAM(video) {
      if (this.course.watchedDuration + 10 < this.course.duration)
        video.currentTime(this.course.watchedDuration)
    },
    PLAYING_VIDEO(video) {
      const ct = video.currentTime()
      // her saniye çalışma süresi sayar.
      this.studyTime += 1
      if (ct <= this.course.maxWatchedDuration + 1)
        this.currentTimes.push(video.currentTime())
      if (this.currentTimes.length > 5) this.currentTimes.shift()
    },
    // 10sn de bir userın durationu kayıt edilir.
    SET_UPDATE_DURATION_TO_API(video) {
      const vm = this
      if (this.single) return
      this.controlBar.updateDurationInterval = setInterval(async () => {
        await this.$api.put('UserCourses', {
          videoId: Number(this.$route.params.videoId),
          isFinished: 0, // video aktif izlendiği için 0 gönderilir.
          watchedDuration: Math.floor(video.currentTime()),
          studyTime: this.studyTime || 0,
        })
        this.studyTime = 0
        vm.$store.commit('productDetail/SET_UPDATE_VIDEO', {
          sectionId: this.videoStream.sectionId,
          videoId: Number(this.$route.params.videoId),
          courseId: Number(this.$route.params.productId),
          video: {
            watchedDuration: Math.floor(video.currentTime()),
          },
        })
        vm.$emit('update:video')
      }, 10000)
    },
    // video bitirildiğinde gönderilir.
    async SET_UPDATE_FINISHED_VIDEO_TO_API(video) {
      if (this.single) return
      const response = await this.$api.put('UserCourses', {
        videoId: Number(this.$route.params.videoId),
        isFinished: 1,
        watchedDuration: Math.floor(video.currentTime()),
        studyTime: this.studyTime,
      })
      if (response.message === 'OK') {
        this.studyTime = 0
        this.$store.commit('productDetail/SET_UPDATE_VIDEO', {
          sectionId: this.videoStream.sectionId,
          videoId: Number(this.$route.params.videoId),
          courseId: Number(this.$route.params.productId),
          nextVideoId: response.data.code !== 40 ? response.data.videoId : 0,
          video: {
            isFinished: 1,
          },
        })
        this.$emit('update:video')
        this.$emit('success:videoStreamIsFinished', 1)
        const quiz = this.FIND_QUIZ()
        if (quiz.id > 0) {
          location.href = this.localePath(
            `/urun/${this.$route.params.productId}/video/${
              response.data.videoId || this.course.videoId
            }?quiz=${quiz.id}`
          )
        } else if (response.data.code === 10) {
          this.$router.replace({
            params: { videoId: response.data.videoId },
          })
        } else if (response.data.code === 20) {
          location.href = this.localePath(
            `/urun/${this.$route.params.productId}/video/${response.data.videoId}`
          )
        } else if (response.data.code === 30) {
          this.showAlertModal = {
            status: true,
            btnText: 'OK',
            body: response.data.message,
            title: this.$custom$t('congrats'),
            ok: () =>
              this.$router.replace({
                params: { videoId: response.data.videoId },
              }),
          }
        } else if (response.data.code === 40) {
          this.showAlertModal = {
            status: true,
            btnText: 'OK',
            body: this.$custom$t('doneCourseMessage'),
            title: this.$custom$t('congrats'),
            ok: () => (this.showAlertModal.status = false),
          }
        } else {
          this.showAlertModal = {
            status: true,
            btnText: 'Sayfamı Yenile',
            body: this.$custom$t('somethingWentWrong'),
            title: this.$custom$t('error'),
            ok: () => this.$router.go(0),
          }
        }
      }
    },
  },
}
